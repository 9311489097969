<template>
	<div class="college-imaging">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex = 'tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>影像学院</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="col-all-list">
					<div class="list-classinfo">
						<div class="l-class-img">
							<img src="../../assets/images/home/renwu.png" alt="">
						</div>
						<div class="list-introduce">
							<span>广告高级班</span>
							<div class="all-dot">
								<div class='dot-item'></div>
								<div class='dot-item'></div>
								<div class='dot-item'></div>
							</div>
							<div class="int-content">
								使针对想要从事摄影摄像的小白，大学生毕业，没有找到合适工作，热爱喜欢影像制作，年龄21—28岁，男女不限，进行15—30天的培训，通过考核即可成为摄影师，入职率100%！一旦成为摄影师，公司即可直签！享受底薪保障，实现摄影剪辑梦想！
							</div>
							<div class="list-int-btn" @click="signupClick()">立即报名</div>
						</div>
					</div>
					<div class="photo-show">
						<div class="yellow-line"></div>
						<span>图片展示</span>
					</div>
					<div class="all-swiper">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<swiper class="swiper" :options="swiperOption">
							<!-- <swiper-slide v-for="i in 8">
								<img src="../../assets/images/home/tu.png" alt="">
							</swiper-slide> -->
						</swiper>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
				<div class="col-all-list">
					<div class="list-classinfo">
						<div class="l-class-img">
							<img src="../../assets/images/home/renwu.png" alt="">
						</div>
						<div class="list-introduce">
							<span>初级高级班</span>
							<div class="all-dot">
								<div class='dot-item'></div>
								<div class='dot-item'></div>
								<div class='dot-item'></div>
							</div>
							<div class="int-content">
								使针对想要从事摄影摄像的小白，大学生毕业，没有找到合适工作，热爱喜欢影像制作，年龄21—28岁，男女不限，进行15—30天的培训，通过考核即可成为摄影师，入职率100%！一旦成为摄影师，公司即可直签！享受底薪保障，实现摄影剪辑梦想！
							</div>
							<div class="list-int-btn" @click="signupClick()">立即报名</div>
						</div>
					</div>
					<div class="photo-show">
						<div class="yellow-line"></div>
						<span>图片展示</span>
					</div>
					<div class="all-swiper">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<swiper class="swiper" :options="swiperOption">
							<!-- <swiper-slide v-for="i in 8">
								<img src="../../assets/images/home/tu.png" alt="">
							</swiper-slide> -->
						</swiper>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- 线上报名弹框 -->
		<el-dialog title="" :visible.sync="dialogTableVisible">
			<div class="online-sign">
				<div class="onl-close" @click="signboxClose">
					<img src="../../assets/images/College-Imaging/baoming_close.png" alt="">
				</div>
				<div class="onl-title">
					<img src="../../assets/images/College-Imaging/bm_title.png" alt="">
				</div>
				<div class="table">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="115px" class="demo-ruleForm">
						<el-form-item label="选择课程：" prop="curriculum">
							<el-select v-model="ruleForm.curriculum" placeholder="请选择">
								<el-option label="课程一" value="shanghai"></el-option>
								<el-option label="课程二" value="beijing"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="姓名：" prop="name">
							<el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="联系电话：" prop="phone">
							<el-input v-model="ruleForm.phone" placeholder="请输入"></el-input>
						</el-form-item>
						<el-button class="online-btn" @click="submitForm('ruleForm')">立即创建</el-button>
					</el-form>
				</div>

			</div>
		</el-dialog>

	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	export default {
		name:'College-Imaging',
		components: {
			BannerOther,
			swiper,
			swiperSlide,
			Sidder
		},
		data() {
			return {
				img_url: require('@/assets/images/home/beijingtu.png'),
				chinese_name: '影像学院',
				english_name: 'COLLEGE OF IMAGEING',
				swiperarr: [require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png'),
					require('../../assets/images/home/tu.png')
				],
				tab_selectIndex: 3,
				width: document.documentElement.clientWidth, //实时屏幕宽度
				swiperOption: {
					slidesPerView: 5,
					spaceBetween: 20,
					loop: true,
					stopOnLastSlide: false,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					on: {
						resize: function(params) {
							var that = this
							if (that.width <= 375) {
								that.params.slidesPerView = 2;
							} else if (that.width <= 768) {
								that.params.slidesPerView = 3;
							} else if (that.width <= 1000) {
								that.params.slidesPerView = 4;
							} else {
								that.params.slidesPerView = 5;
							}
							this.update(); //窗口变化时，更新Swiper的一些属性，如宽高等
						}
					}
				},
				dialogTableVisible: false,
				ruleForm: {
					curriculum: '',
					name: '',
					phone: ''
				},
				rules: {
					curriculum: [{
						required: true,
						message: '请选择课程！',
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请输入姓名！',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系电话！',
						trigger: 'blur'
					}]
				}
			}
		},
		activated() {
			var that = this
			// 控制轮播图显示个数
			if (that.width <= 375) {
				that.swiperOption.slidesPerView = 2;
			} else if (that.width <= 768) {
				that.swiperOption.slidesPerView = 3;
			} else if (that.width <= 1200) {
				that.swiperOption.slidesPerView = 4;
			} else {
				that.swiperOption.slidesPerView = 5;
			}
		},
		methods: {
			
			signboxClose(){
				var that = this
				that.dialogTableVisible = false;
				that.ruleForm.curriculum = '';
				that.ruleForm.name = '';
				that.ruleForm.phone = '';
				
			},
			// 提交报名
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
			 	}
				});
			},
			// 线上弹框的显示及传参
			signupClick() {
				var that = this
				that.dialogTableVisible = true;
			}
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/college-imaging.scss';
	@import '../../assets/css/public.scss';
</style>
